






















































































































































import Vue from 'vue';
import { email } from 'vuelidate/lib/validators';
import CloseButton from '@/blueprint/components/ui/CloseButton.vue';
import Captcha from '@/blueprint/components/shared/ReCaptcha.vue';
import { ValidateFields, Captialize } from '@/assets/mixins';

import gql from 'graphql-tag';
import { GQLTagRequestObject } from '@/assets/clients/gqlClient';
import { API } from '@lordly/models2/interfaces/gql';
import { Enquiry } from '@lordly/models2/interfaces/models/Message';

export default Vue.extend({
  name: 'Enquiry-Component',
  components: {
    'close-btn': CloseButton,
    'captcha': Captcha,
  },
  props: {
    landlordId: {
      type: String,
      required: true,
      default: '',
    },
    propertyId: {
      type: String,
      required: false,
      default: '',
    },
    city: {
      type: String,
      required: false,
      default: '',
    },
  },
  data: () => {
    return {
      showEnquiryForm: false,
      form: {
        email: '',
        firstname: '',
        surname: '',
        message: '',
      },
      enquiryId: '',
      enquirySending: false,
      enquirySent: false,
      recaptchaToken: '',
    };
  },
  validations: {
    form: {
      email: {
        email,
      },
    },
  },
  methods: {
    invokEnquiryForm () {
      this.showEnquiryForm = true;
    },
    closeEnquiryForm () {
      this.showEnquiryForm = false;
    },
    validateFields (inputs: string[]) {
      return ValidateFields(this, inputs);
    },
    async submitEnquiry () {
      // Check for errors
      let hasError: boolean = !this.validateFields(['from', 'firstname', 'surname', 'message']);
      this.$v.$touch();
      // If no errors
      if (!hasError && !this.$v.form!.email!.hasError) {
        // Determine if they have verified that they are not a robot
        if (this.recaptchaToken) {
          this.enquirySending = true;
          try {
            // Create query
            const query: GQLTagRequestObject = gql`
              mutation ($landlordId: String!, $propertyId: String, $city: String, $email: String!, $firstname: String!, $surname: String!, $message: String!, $token: String!) {
                CreateEnquiry (
                  input: {
                  landlordId: $landlordId,
                  propertyId: $propertyId,
                  city: $city,
                  email: $email,
                  firstname: $firstname,
                  surname: $surname,
                  message: $message,
                  token: $token
                  }
                ) {
                  id
                }
              }
            `;
            // Create query payload
            const payload: API.CreateEnquiryInput = {
              landlordId: this.landlordId,
              propertyId: this.propertyId || undefined,
              city: this.city || undefined,
              email: this.form.email,
              firstname: Captialize(this.form.firstname)!,
              surname: Captialize(this.form.surname)!,
              message: Captialize(this.form.message)!,
              token: this.recaptchaToken,
            };
            // Perform request
            const response: Partial<Enquiry> = await this.$gql.Mutation('CreateEnquiry', query, payload);
            // Set enquiry id
            this.enquiryId = response.id!;
            this.enquirySent = true;
          } catch (e) {
            console.error(e);
          } finally {
            this.enquirySending = false;
            // this.$lga.TrackEnquiry(this.landlordId, this.propertyId, this.city);
          }
        } else {
          this.$q.notify({
            message: 'Please confirm that you are not a robot',
            color: 'red',
            timeout: 5000,
          });
        }
      }
    },
  },
});
