
















































import Vue from 'vue';
import Loading from '@/blueprint/components/ui/Loading.vue';
import PortfolioProfile from '@/blueprint/components/shared/PortfolioProfile.vue';
import Enquiry from '@/blueprint/components/search/Enquiry.vue';
import Result from '@/blueprint/components/search/Result.vue';

import gql from 'graphql-tag';
import { GQLTagRequestObject } from '@/assets/clients/gqlClient';
import { API } from '@lordly/models2/interfaces/gql';
import { User } from '@lordly/models2/interfaces/models/User';
import { Property } from '@lordly/models2/interfaces/models/Property';
import { ComputePagination } from '@/assets/mixins';

export default Vue.extend({
  name: 'Portfolio-Page',
  components: {
    loading: Loading,
    profile: PortfolioProfile,
    enquiry: Enquiry,
    property: Result,
  },
  data () {
    return {
      landlord: {
        id: '',
        image: '',
        firstname: '',
        surname: '',
        description: '',
      },
      portfolio: {
        properties: [] as Array<Partial<Property>>,
        display: [] as Array<Partial<Property>>,
        currentPage: 1,
        totalPages: 1,
        pageSize: 12,
      },
      portfolioLoading: false,
    };
  },
  created () {
    if (this.$route.params.id) {
      this.landlord.id = this.$route.params.id;
      this.getUserInformation();
    } else {
      this.$router.push('/');
    }
  },
  methods: {
    async getUserInformation () {
      // Set loading
      this.portfolioLoading = true;
      // Try doing the query
      try {
        // Create Query
        const query: GQLTagRequestObject = gql`
          query ($id: String!, $partition: UserType!) {
            GetUser(
              input: {
                id: $id,
                partition: $partition
              }
            ) {
              id
              profile {
                firstname
                surname
                description
                image {
                  url
                }
              }
              portfolio {
                id
                available
                images {
                  url
                }
                address {
                  line1
                  city
                  postcode
                  latlng {
                    lat
                    lng
                  }
                }
                details {
                  bedroom
                  toilet
                  type
                  ensuite
                }
                dates {
                  tenancyStart
                  tenancyEnd
                  duration {
                    monthsRounded
                  }
                }
                rent {
                  annually
                  computed {
                    weekly
                    monthly
                    yearly
                  }
                }
                deposit {
                  total
                  computed {
                    perperson
                  }
                }
              }
            }
          }
        `;
        // Create payload
        const payload: API.GetUserInput = {
          id: this.landlord.id,
          partition: 'Landlord',
        };
        // Send Request
        const response: Partial<User> = await this.$gql.Query('GetUser', query, payload);
        // Extract profile
        const landlordProfile: User['profile'] = response.profile!;
        // Map
        this.landlord.image = (landlordProfile.image.length > 0) ? landlordProfile.image[0].url : '';
        this.landlord.firstname = landlordProfile.firstname;
        this.landlord.surname = landlordProfile.surname;
        this.landlord.description = landlordProfile.description;
        // Map Portfolio
        this.portfolio.properties = response.portfolio as unknown as Array<Partial<Property>>;
        // BugFix: Filter out available properties only
        this.portfolio.properties = this.portfolio.properties.filter((property) => property.available);
        // Compute pagination
        ComputePagination(this.portfolio, 'properties');
      } catch (e) {
        console.error(e);
      } finally {
        this.portfolioLoading = false;
      }
    },
    updatePagination () {
      ComputePagination(this.portfolio , 'properties');
    },
  },
});
