var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"enquiry-container"},[_c('q-btn',{staticClass:"enquiry-btn",attrs:{"round":"","size":"1.1rem","color":"secondary","icon":"mdi-email"},on:{"click":function($event){return _vm.invokEnquiryForm()}}},[_c('q-tooltip',{attrs:{"transition-show":"scale","transition-hide":"scale","anchor":"center left","self":"center right","offset":[10, 10]}},[_vm._v("Enquiry")])],1),(_vm.showEnquiryForm)?_c('div',{staticClass:"enquiry-form-container"},[_c('div',{staticClass:"container-inner"},[_c('close-btn',{staticClass:"enquiry-close",on:{"close":function($event){_vm.showEnquiryForm = false}}}),(!_vm.enquirySent)?_c('form',{staticClass:"enquiry-form",attrs:{"method":"POST"},on:{"submit":function($event){$event.preventDefault();return _vm.submitEnquiry()}}},[_c('h6',[_vm._v("Enquiry Form")]),_c('div',{staticClass:"row segment"},[_c('div',{staticClass:"col-12"},[_c('label',[_vm._v("From:")]),_c('q-input',{ref:"from",attrs:{"error":_vm.$v.form.email.$error,"rules":[
                function (val) { return !!val || '* Required'; },
                function (val) { return val.length < 100 || 'Please use less than 100 characters are maximum'; }
              ],"lazy-rules":"","clearble":"","debounce":"250","outlined":"","placeholder":"E.g. nikola.tesla@science.com"},on:{"input":function($event){return _vm.$v.form.email.$touch()}},scopedSlots:_vm._u([{key:"error",fn:function(){return [_vm._v(" "+_vm._s(_vm.$v.form.email.$error ? 'Please enter a valid email address' : '')+" ")]},proxy:true}],null,false,366751356),model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1)]),_c('div',{staticClass:"row segment"},[_c('div',{staticClass:"col-xs-12 col-sm-6 sm-pad-r"},[_c('label',[_vm._v("Firstname:")]),_c('q-input',{ref:"firstname",attrs:{"rules":[
                function (val) { return !!val || '* Required'; },
                function (val) { return val.length < 100 || 'Please use less than 100 characters are maximum'; }
              ],"lazy-rules":"","clearble":"","debounce":"250","outlined":"","placeholder":"E.g. Nikola"},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}})],1),_c('div',{staticClass:"col-xs-12 col-sm-6 sm-pad-l"},[_c('label',[_vm._v("Surname:")]),_c('q-input',{ref:"surname",attrs:{"rules":[
                function (val) { return !!val || '* Required'; },
                function (val) { return val.length < 100 || 'Please use less than 100 characters are maximum'; }
              ],"lazy-rules":"","clearble":"","debounce":"250","outlined":"","placeholder":"E.g. Tesla"},model:{value:(_vm.form.surname),callback:function ($$v) {_vm.$set(_vm.form, "surname", $$v)},expression:"form.surname"}})],1)]),_c('div',{staticClass:"row segment"},[_c('div',{staticClass:"col-12"},[_c('label',[_vm._v("Message:")]),_c('q-input',{ref:"message",attrs:{"rules":[
                function (val) { return !!val || '* Required'; },
                function (val) { return val.length > 10 || 'Please use more than 10 characters are minimum'; },
                function (val) { return val.length < 2500 || 'Please use less than 2500 characters are maximum'; }
              ],"lazy-rules":"","clearble":"","debounce":"250","outlined":"","placeholder":"Your message...","type":"textarea","input-style":"height: 20rem"},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}})],1)]),_c('captcha',{on:{"token":function (token) { _vm.recaptchaToken = token }}}),_c('q-btn',{staticClass:"full-width",staticStyle:{"margin-top":"1rem"},attrs:{"ripple":false,"loading":_vm.enquirySending,"disable":_vm.enquirySending,"no-caps":"","flat":"","unelevated":"","color":"secondary","type":"submit"}},[_vm._v("Send")])],1):_vm._e(),(_vm.enquirySent)?_c('div',{staticClass:"enquiry-confirmation center vh"},[_c('p',[_vm._v("Thank you, we've got your enquiry!")]),_c('p',[_vm._v("Keep an eye out for an email from us!!")]),_c('p',[_vm._v("Your enquiry reference is:")]),_c('p',[_c('strong',[_vm._v(_vm._s(this.enquiryId))])])]):_vm._e()],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }